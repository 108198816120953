<template lang="pug">
	.main-wrapper.unidades-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / <b>Protocolos</b>

		Dialog.dialogApagar(header='Remover protocolo ' :visible.sync='dialogApagar' :modal='true')
			p Deseja remover o protocolo <b>{{ dialogApagar_data.ds_titulo }}</b>?
			.ta-center.my-4
				ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
				Button.p-button-danger(v-else label='Remover' @click='remove()')
				
		Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
			.p-grid.p-fluid.p-align-end

				.p-col-12.p-md-4
					label.form-label Pesquisar:
					.p-inputgroup
						InputText(
							:autoClear='false'
							placeholder='(Pesquise por título ou especialidade)'
							@keyup.enter.native='applyFilters()'
							@input='checkEmptyField("ds_busca")'
							v-model='filters.ds_busca'
						)
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-12.p-md-4
					label.form-label Especialidade:
					Dropdown( v-model='filters.cd_especialidade' :options='options.especialidades' dataKey='id' :filter='options.especialidades.length > 0'
						optionLabel='nm_especialidade' optionValue='id' placeholder='TODAS' @change='applyFilters()' )

				.p-col-12.p-md-4
					label.form-label Tipo:
					Dropdown( v-model='filters.ie_tipo' :options='options.ie_tipo' dataKey='key'
						optionLabel='label' optionValue='key' placeholder='TODOS' @change='applyFilters()' )
							
		.ta-right.my-2
			Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/protocolos/salvar/0/')")

		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)
			Panel.datatable(header='Lista de Protocolos')
				Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
				DataTable(:value="list")

					Column(headerStyle='width: 25%;' field='ds_titulo' header='Título')
					Column(headerStyle='width: 25%;' field='ds_protocolo' header='Descrição')
					Column(headerStyle='width: 25%;' header='Especialidades')
						template(#body='props')
							.mtd-badge.co1(v-for='especialidade in props.data.especialidades') {{ especialidade }}
					Column(headerStyle='width: 22%;' header='Tipo')
						template(#body='props')
							span {{ options.ie_tipo.find(item => item.key === props.data.ie_tipo).label }}
					Column(headerStyle='width: 8%; text-align: center' header='Ações')
						template(#body='props')
							.ta-center
								Button.p-button-raised.p-button-rounded.mr-1(
									v-tooltip.top="'Editar'"
									icon="jam jam-write"
									@click="$router.push(`/protocolos/salvar/${ props.data.cd_protocolo }/`)")
								Button.p-button-raised.p-button-rounded.p-button-danger(
									v-tooltip.top="'Apagar'"
									icon="jam jam-minus-circle"
									@click="dialogApagar = true; dialogApagar_data = props.data")
				Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
	.unidades-listar {
		.dataview {
			@media all and (min-width: 577px) {
				display: none;
			}
		}
		.datatable {
			@media all and (max-width: 576px) {
				display: none;
			}
			.cell {
				padding: 16px 0;
				text-align: center;
				&.ex  {  background-color: #e4f8e1; }
				&.a  { background-color: #faf3dd; }
			}
		}
		.dialogApagar {
			.p-progress-spinner {
				width: 29px;
				height: auto;
			}
		}
	}
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
	import ProgressBar from 'primevue/progressbar'
	import DataView from 'primevue/dataview'
	import Panel from 'primevue/panel'
	import Paginator from 'primevue/paginator'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Button from 'primevue/button'
	import Tooltip from 'primevue/tooltip'
	import Dialog from 'primevue/dialog'
	import InputText from 'primevue/inputtext'
	import ProgressSpinner from 'primevue/progressspinner'
	import Dropdown from 'primevue/dropdown'

	import { Protocolo, Agendamento } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
	export default {
		created () {
			this.waiting = true
			Agendamento.getEspecialidades().then(response => {
				if (response.status === 200) this.options.especialidades = response.data
				this.options.especialidades.unshift({ id: null, nm_especialidade: 'TODAS' })
				this.applyFilters()
			})
		},
		components: { ProgressBar, DataView, Panel, Paginator, DataTable, Dropdown,
			Column, Button, Tooltip, Dialog, ProgressSpinner, InputText },
		directives: { tooltip: Tooltip },
		data () {
			return {
				list: [],
				windowInnerWidth: window.innerWidth,
				waiting: false,
				waitingApagar: false,
				dialogApagar: false,
				dialogApagar_data: {},
				options: {
					especialidades: [],
					ie_tipo: [
						{ key: null, label: 'TODOS' },
						{ key: 'EX', label: 'Pedido de exame' },
						{ key: 'RE', label: 'Receita' },
						{ key: 'AT', label: 'Atestado' }
					]
				},
				filters: {
					ds_busca: '',
					cd_especialidade: null,
					ie_tipo: null
				},
				paginator: {
					page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				}
			}
		},
		methods: {
			getList (params) {
				this.waiting = true
				this.list = []
				return Protocolo.findAll(params).then(response => {
					if (response.status == 200) {
						this.paginator.count = response.data.count

						response.data.results.forEach(protocolo => {
							if (protocolo.cd_protocolo) {
								let find = this.list.find(p => p.cd_protocolo === protocolo.cd_protocolo)
								if (! find) {
									this.list.push({
										cd_protocolo: protocolo.cd_protocolo,
										ds_titulo: protocolo.ds_titulo,
										ds_protocolo: protocolo.ds_protocolo,
										ie_tipo: protocolo.ie_tipo,
										especialidades: [ protocolo.nm_especialidade ]
									})
								} else find.especialidades.push(protocolo.nm_especialidade)
							}
						})

					}
					this.waiting = false
					return true
				})
			},
			applyFilters () {
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
				this.getList(params)
			},
			checkEmptyField (field) {
				if (! this.filters[field].length) this.applyFilters()
			},
			onPage (ev) {
				this.paginator.page = ev.page + 1
				this.applyFilters()
			},
			remove () {
				this.waitingApagar = true
				Protocolo.remove(this.dialogApagar_data.cd_protocolo).then(response => {
					this.waitingApagar = false
					if (([200, 201, 204]).includes(response.status)) {
						this.$toast.info('Protocolo removido com sucesso', { duration: 3000 })
						this.dialogApagar = false
						this.applyFilters()
					} else if (response.data.detail) {
						if (typeof response.data.detail == 'string')
							this.$toast.error(response.data.detail, { duration: 3000 })
						else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
					}
				})
			}
		}
	}
</script>